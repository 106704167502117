html {
  height: 100%;
}

body {
  background: white;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #505050;
}

a {
  color: #2b5a87;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
}

h1,
h2,
h3 {
  font-weight: 500;
}

/** Policy Agreements App */

.header {
  padding: 1rem 0;
  background-color: #fff;
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.05);
  z-index: 1;
  position: relative;
  border-top: 1px solid #f3f3f3;
  /* Needed for IE11 */
  min-height: 2.375rem;
}

.logo {
  max-width: 112px;
  /* Needed for IE11 */
  width: 100%;
}

.footer {
  flex: 0;
  border-top: 1px solid #c5c9cc;
  padding: 1rem 1.5rem;
  color: #505050;
  font-size: 0.6875rem;
}

#app {
  flex-grow: 1;
  padding: 0 1rem;
}

.policyAgreementAppLoading {
  text-align: center;
  padding: 2rem 0;
}

.policyAgreementAppLoading span {
  font-size: 1.5rem;
  line-height: 2rem;
}

.policyAgreementAppError {
  max-width: 37.5rem;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
}

.policyAgreementAppCanceled {
  max-width: 24rem;
  margin: 2rem auto 1rem;
  display: flex;
  flex-direction: column;
}

.policyAgreementAppCanceled .button .flag {
  padding-right: 0.25rem;
}

.policyAgreementForm {
  max-width: 50rem;
  margin: 2rem auto 1rem;
}

.policyAgreementActions {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  padding: 2rem 0 1rem;
}

.policyAgreementActions > button {
  margin-left: 0;
  margin-right: 0;
}

.policyAgreementActions > button:last-child {
  margin-top: 0;
  margin-bottom: 0;
}

@media all and (min-width: 500px) {
  .policyAgreementActions {
    flex-direction: row;
  }

  .policyAgreementActions > button {
    margin: 0 1rem;
  }

  .policyAgreementActions > button:last-child {
    margin-left: 0;
    margin-right: 0;
  }
}

.policyAgreementFrame {
  display: block;
  border: 0;
  width: 100%;
  height: 18.75rem;
}

/** Buttons */

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 2.5rem;
  margin: 1rem;
  padding: 0 1rem;
  border: 0;
  border-radius: 2rem;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.button:disabled {
  cursor: unset;
}

.button.button-primary {
  min-width: 15rem;
  color: white;
  background: linear-gradient(to left, #407aa0, #2b5a87);
}

.button.button-primary:hover,
.button.button-primary:focus {
  background: #407aa0;
}

.button.button-secondary {
  min-width: 5.5rem;
  color: #2b5a87;
  background: #fff;
  border: 2px solid #2b5a87;
}

.button.button-secondary:hover,
.button.button-secondary:focus {
  background: #ecf0f5;
}

.button.button-secondary:active {
  background: #dae2ea;
}

.button.button-low-emphasis {
  min-width: 5.5rem;
  color: #2b5a87;
  background: transparent;
}

.button.button-low-emphasis:hover,
.button.button-low-emphasis:focus {
  background: rgba(0, 0, 0, 0.06);
}

/** Accordion */

.accordion {
  margin: 0;
  padding: 0;
  border: 1px solid #c8c8c8;
}

.accordion h3 {
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  color: #505050;
}

.accordion-trigger {
  border: 0;
  background: none;
  color: hsl(0, 0%, 13%);
  display: block;
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  padding: 1em 1.5em;
  position: relative;
  text-align: left;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.accordion-trigger:focus {
  background: rgba(0, 0, 0, 0.04);
}

.accordion-title {
  display: block;
  pointer-events: none;
  border: transparent 2px solid;
  border-radius: 5px;
  padding: 0.25em;
  outline: none;
}

.accordion-icon {
  border: solid #2b5a87;
  border-width: 0 2px 2px 0;
  height: 0.5rem;
  pointer-events: none;
  position: absolute;
  right: 2em;
  top: 50%;
  transform: translateY(-60%) rotate(45deg);
  transition: transform 0.333s;
  width: 0.5rem;
}

.accordion-trigger[aria-expanded="true"] .accordion-icon {
  transform: translateY(-50%) rotate(-135deg);
}

.accordion-item {
  border-bottom: 1px solid #c8c8c8;
}

.accordion-item:last-child {
  border-bottom: 0;
}

.accordion-panel {
  transition: max-height 0.333s;
  /* Frame height is 18.75rem */
  max-height: 19rem;
  overflow: hidden;
}

.accordion-panel[aria-hidden="true"] {
  max-height: 0;
}

/** Loading spinners */
/** https://loading.io/css/ */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #2b5a87;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2b5a87 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
